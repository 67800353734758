import styled from "styled-components";
import { colors, fonts } from "styles";

import Small from "assets/images/franchising2024/clinica_samall.png";
import Medium from "assets/images/franchising2024/clinica_medium.png";
import Big from "assets/images/franchising2024/clinica_big.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  min-height: 350px;
  background: #141418;
  padding: 100px 10%;
  @media screen and (max-width: 1720px) {
    align-items: center;
    padding: 100px 50px;
  }
  @media screen and (max-width: 900px) {
    padding: 50px 30px;
  }
`;
export const Title = styled.h2`
  font-family: ${fonts.BrandontextBold};
  color: ${colors.orange};
  font-size: 60px;
  margin-bottom: 5px;
  @media screen and (max-width: 900px) {
    margin: 20px auto;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    font-size: 40px;
  }
`;
export const Text = styled.p`
  font-family: ${fonts.BrandontextRegular};
  color: ${colors.white};
  font-size: 25px;
  line-height: 1.1em;
  max-width: 500px;
  margin-bottom: 30px;
  span {
    font-family: ${fonts.BrandontextBold};
  }
  @media screen and (max-width: 1590px) {
    text-align: center;
  }
  @media screen and (max-width: 900px) {
    margin: 0 auto;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const GroupPlans = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 20px;
  margin-top: 200px;
  @media screen and (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
    row-gap: 250px;
    max-width: 400px;
  }
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 95%;
  max-width: 450px;
  min-height: 400px;
  background: ${colors.orange};
  padding: 20px;
  h2 {
    font-family: ${fonts.BrandontextBold};
    color: ${colors.white};
    font-size: 25px;
    text-align: center;
    line-height: 1.1em;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  p {
    font-family: ${fonts.BrandontextBold};
    color: ${colors.white};
    font-size: 13px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  p.price {
    font-family: ${fonts.BrandontextBlack};
    color: ${colors.dark};
    font-size: 19px;
  }
  @media screen and (max-width: 900px) {
    max-width: 300px;
    margin: 0 auto;
  }
`;

export const ImgSmall = styled.img.attrs({ src: Small })`
  position: absolute;
  width: 100%;
  max-width: 250px;
  top: -130px;
`;
export const ImgMedium = styled.img.attrs({ src: Medium })`
  position: absolute;
  width: 100%;
  max-width: 300px;
  top: -160px;
`;
export const ImgBig = styled.img.attrs({ src: Big })`
  position: absolute;
  width: auto;
  height: 350px;
  top: -180px;
`;

export const GroupWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 20px 0;
  max-width: 900px;
  margin-top: 20px;
  p {
    font-size: 20px;
    color: #fff;
    max-width: 400px;
    text-align: center;
    line-height: 1.4em;
  }
`;
